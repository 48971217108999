
const Vietnamese = {
  "norman-vincent-peale":"Con đường dẫn đến hạnh phúc: Giữ cho trái tim bạn thoát khỏi hận thù, tâm trí bạn thoát khỏi lo lắng. Sống đơn giản, kì vọng ít, cho đi nhiều hơn. Tỏa nắng, quên mình, nghĩ đến người. Hãy thử điều này trong một tuần và bạn sẽ ngạc nhiên.",
  "mahatma-gandhi-quote1": "Hạnh phúc là khi những gì bạn nghĩ, những gì bạn nói và những gì bạn làm đều hòa hợp với nhau.",
  "albert-schweitzer-quote": "Thành công không phải là chìa khóa dẫn đến hạnh phúc. Hạnh phúc là chìa khóa dẫn đến thành công. Nếu bạn yêu thích việc mình đang làm, bạn sẽ thành công.",
  "maharishi-yogi-quote": "Suy nghĩ là hạt giống của hành động. Hành động dẫn đến sự thỏa mãn.",
  "dalai-lama-quote": "Mục đích cuộc sống của chúng ta là hạnh phúc.",
  "steve-jobs-quote": "Thời gian của bạn là có hạn, đừng lãng phí để sống cuộc đời của người khác.", 
  "martin-luther-king-quote": "Thời điểm luôn đúng để làm điều đúng.",
  "gro-harlem-brundtland-quote": "Phát triển bền vững là sự phát triển đáp ứng nhu cầu của hiện tại mà không ảnh hưởng đến các thế hệ tương lai.",
  "mahatma-gandhi-quote2": "Hãy trở thành sự thay đổi mà bạn muốn thấy trên thế giới.",
  "aristotle-quote": "Chúng ta là những gì chúng ta làm nhiều lần. Do vậy, sự xuất sắc không phải là một hành động mà là một thói quen.",
  "eleanor-roosevelt-quote": "Tương lai thuộc về những ai tin vào vẻ đẹp trong ước mơ của mình.",
  "henry-david-thoreau-quote": "Tự tin đi theo hướng ước mơ của bạn. Hãy sống cuộc sống mà bạn đã tưởng tượng.",
  "nelson-mandela-quote": "Giáo dục là vũ khí mạnh nhất mà bạn có thể sử dụng để thay đổi thế giới.",
  "thankyou": "Cảm ơn bạn nhiều.",
  "happinesspath": "Con đường hạnh phúc",
  "lifeprinciples": "Nguyên tắc sống",
  "author": "Tác giả",
  "profile":[
    `Chào mừng đến trang web của tôi!`,
    `Tôi là một chuyên gia máy tính kinh nghiệm tại Việt Nam, Hàn Quốc và Hoa Kỳ, đảm nhiệm các vai trò đa dạng từ kĩ sư phần mềm đến nhà giáo dục. Sau khi lấy được bằng về công nghệ thông tin và Mạng Máy tính từ các trường đại học ở Việt Nam và Hàn Quốc, tôi đã hoàn thành một bằng Thạc sĩ Khoa học Máy tính khác ở Hoa Kỳ. Hiện tại, tôi giảng dạy Khoa học Máy tính ở Hoa Kỳ, nơi tôi chia sẻ kiến ​​thức và kinh nghiệm của mình với những sinh viên đầy nhiệt huyết.`,
    `Niềm đam mê của tôi nằm ở việc phát triển phần mềm và khám phá những công nghệ tiên tiến. Tôi cũng là người thực hành Thiền Siêu Việt từ năm 2015, phương pháp này giúp tôi kiểm soát căng thẳng và đón nhận những thay đổi nhanh chóng của cuộc sống một cách hiệu quả.`,
    `Trang web này đóng vai trò như một nền tảng để chia sẻ những nguyên tắc và kinh nghiệm đã định hình nên cuộc sống cân bằng và trọn vẹn của tôi. Cảm ơn bạn đã ghé thăm và tôi hy vọng sẽ truyền cảm hứng cũng như tăng thêm giá trị cho hành trình của bạn.`
  ],
  "title1": "Hành trình tới Hạnh phúc đích thực",
  "paragraphs1": [
    `Chào mừng bạn đến với hành trình thay đổi của tôi, câu chuyện biến nghịch cảnh thành cuộc sống hạnh phúc. Hai mươi năm trước, tôi quyết định rời quê hương để tìm cách vực dậy gia đình khỏi cảnh nghèo đói. Khát khao này đã đưa tôi đi khắp các châu lục, từ những con phố nhộn nhịp của Việt Nam đến những thành phố sôi động của Hàn Quốc và Hoa Kỳ. Khi trải nghiệm sự phức tạp của cuộc sống ở những nền văn hóa đa dạng này, tôi phải vật lộn với câu hỏi muôn thuở: Bản chất thực sự của hạnh phúc là gì?`,
    `Ban đầu, tôi học với nhiều người xung quanh và tin rằng nếu kiếm được nhiều tiền hơn thì tôi sẽ có cuộc sống hạnh phúc. Tuy nhiên, hai thập kỷ kinh nghiệm đã vẽ nên một bức tranh khác đối với tôi. Cần có sự giàu có để chống lại nghèo đói, nhưng nó không phải là thứ duy nhất tạo nên một cuộc sống mãn nguyện. Tôi đã thấy rằng đời sống vật chất không đảm bảo được niềm vui.`,
    `Những nhận thức của tôi ngày càng sâu sắc hơn. Tôi đã phát hiện ra rằng hạnh phúc chủ yếu nằm ở việc nắm vững nghệ thuật vượt qua những thử thách không thể tránh khỏi trong cuộc sống. Nó thiên về việc tìm kiếm sự bình yên và niềm vui hàng ngày, bất kể điều gì. Tôi đã chiến đấu chống lại những trở ngại khó khăn trong cuộc sống của mình, như nghèo đói, bệnh tật và gia đình ly tán. Qua tất cả, tôi học được rằng của cải trần thế chỉ là tiện nghi phù du. Hạnh phúc đích thực đến từ bên trong, từ sự bình yên và niềm vui không gì lay chuyển được hàng ngày trong cuộc sống giản dị.`,
    `Nếu bạn khao khát một cuộc sống giàu hạnh phúc đích thực, nơi bình yên và niềm vui không chỉ là những khoảnh khắc thoáng qua mà là trạng thái tồn tại thường xuyên, hãy để cuộc hành trình của tôi truyền cảm hứng cho bạn. Đó không chỉ là minh chứng cho sức mạnh tinh thần và sự kiên cường của con người mà còn là lời nhắc nhở rằng kho báu lớn nhất trong cuộc đời không phải là những thứ chúng ta có thể cầm trên tay mà là những thứ chúng ta mang trong tim.`
  ],
  "title2": `Ưu tiên kiến thức`,
  "paragraphs2":[
    `Tôi 17 tuổi, đang đứng trước ngã ba đường quan trọng của cuộc đời. Hầu hết bạn bè của tôi đều đi học ở các trường đại học gần quê hương. Tuy nhiên, tôi vẫn quyết định định hình tương lai của mình: Tôi rời quê hương để theo đuổi con đường học vấn cao hơn ở thành phố lớn nhất đất nước (Thành phố Hồ Chí Minh, Việt Nam). Với trái tim đầy ước mơ và trí óc ham học hỏi, tôi may mắn được nhận vào một trong những trường đại học danh tiếng nhất nước. Đó không chỉ là một cơ hội học tập và là ngọn hải đăng hy vọng cho một tương lai tốt đẹp hơn.`,
    `Tuy nhiên, tôi biết rằng cơ hội này đi kèm với những thách thức của nó. Hỗ trợ tài chính từ gia đình tôi rất hạn chế và tôi thấy mình phải vật lộn với thực tế tự nuôi sống bản thân ở thành phố nhộn nhịp. Quyết tâm đi theo con đường của mình, tôi đã làm gia sư toán bán thời gian. Công việc này không chỉ là phương tiện sinh tồn mà còn là sự cam kết của tôi đối với việc học.`,
    `Kiếm tiền thật thú vị và hấp dẫn. Sức hấp dẫn của việc kiếm được nhiều tiền hơn bằng cách tăng số giờ dạy kèm thật là hấp dẫn, nhưng tôi hiểu rằng việc đi chệch hướng học tập sẽ ảnh hưởng đến các mục tiêu dài hạn của tôi. Vì vậy, tôi đã đưa ra một lựa chọn có vẻ trái ngược với nhiều người: Tôi vay tiền từ bạn bè, người thân và chấp nhận khoản hỗ trợ tài chính tối thiểu từ gia đình. Quyết định này được thúc đẩy bởi niềm tin vững chắc rằng việc học của tôi là sự đầu tư cho tương lai tốt đẹp hơn của tôi, nền tảng để tôi có thể xây dựng một cuộc sống ổn định và thành công.`,
    `Năm năm sau, tôi tốt nghiệp với điểm cao, kiến thức phong phú và sự tự tin mới có được. Nền tảng giáo dục này không chỉ là chứng chỉ học thuật mà còn là chìa khóa mở ra những cánh cửa cơ hội mà tôi từng mơ ước. Tôi nhanh chóng có được một công việc tốt, một thành tựu bắt đầu một chương mới mà những khó khăn về tài chính không còn cản trở khát vọng của tôi nữa.`,
    `Đó chỉ mới bắt đầu cuộc sống của tôi. Kiến thức và kỹ năng tôi có được cho phép tôi học tập, làm việc và phát triển ở những nơi sôi động ở Hàn Quốc và Hoa Kỳ. Đó là một hành trình đáng chú ý từ các lớp học ở Việt Nam đến với sân khấu toàn cầu, với điều kiện là giáo dục thực sự là công cụ cân bằng lớn nhất.`,
    `Nhìn lại, nếu tôi ưu tiên thu nhập trước mắt hơn việc học, cuộc sống của tôi có thể đã khác. Mọi thử thách tôi chấp nhận và mỗi sự hy sinh tôi thực hiện đều phục vụ một mục tiêu duy nhất: phát triển thành một kỹ sư có kiến thức sâu rộng và kỹ năng tinh tế. Và trong quá trình theo đuổi này, tôi đã thành công, vạch ra lộ trình hơn một thập kỷ qua nhiều quốc gia.`,
    `Tóm lại, kiến thức luôn phải được đặt lên hàng đầu. Hãy trân trọng những gì bạn yêu thích, cống hiến hết mình cho việc học và xuất sắc trong lĩnh vực bạn đã chọn. Kiến thức không chỉ là một hành trình học thuật; đó là một cuộc hành trình mở đường cho một cuộc sống trọn vẹn và thịnh vượng. Không ngừng học hỏi, tiếp thu kiến thức mới.`
  ],
  "title3": `Hành động đúng`,
  "paragraphs3": [
    `Cách đây vài năm, tôi đã phải đối mặt với một thời điểm quan trọng trong cuộc đời mình. Công ty khởi nghiệp của tôi đã thất bại, khiến tôi rơi vào tình trạng tài chính bấp bênh. Thêm vào những rắc rối của tôi, tôi được chẩn đoán mắc bệnh nghiêm trọng do làm việc ăn uống không tốt, điều này càng làm tăng thêm nhu cầu về thu nhập ổn định của tôi. Tôi bắt đầu tìm kiếm việc làm trong khoảng thời gian đầy thử thách này và nhanh chóng nhận được nhiều lời mời hấp dẫn. Trong số này có một dự án quan trọng đã được thực hiện trong hai năm.`,
    `Tôi đã dành gần một tháng để nghiên cứu dự án này đồng thời đang điều trị y tế. Tuy nhiên, càng tìm hiểu sâu về nó, tôi càng nhận ra sự sai lệch rõ ràng giữa các giá trị của mình và quỹ đạo của dự án. Nó dường như tập trung vào việc làm cạn kiệt nguồn vốn của nhà đầu tư hơn là mang lại giá trị đích thực cho khách hàng. Một dự án tốt vốn dĩ phải mang lại lợi ích cho khách hàng và xã hội chứ không chỉ tiêu tốn tài nguyên.`,
    `Đối mặt với tình thế tiến thoái lưỡng nan về mặt đạo đức, tôi quyết định từ chối lời đề nghị béo bở này. Thay vào đó, tôi chọn một dự án nhỏ hơn với một công ty khác phù hợp hơn với các nguyên tắc của tôi. Hóa ra, đó là một quyết định tuyệt vời. Nó, mặc dù ít lợi ích hơn về mặt tài chính, nhưng lại tỏ ra vô cùng thỏa mãn về mọi mặt. Môi trường làm việc rất phong phú và tôi có một đội ngũ làm việc đáng kinh ngạc. Bầu không khí tích cực này đóng một vai trò quan trọng trong việc phục hồi tài chính và sức khỏe của tôi. Sức khỏe của tôi được cải thiện đáng kể trong vòng sáu tháng và công việc của tôi tiến triển nhanh chóng.`,
    `Tôi đã học được một bài học vô giá qua trải nghiệm này: bạn sẽ luôn phải đối mặt với những lựa chọn ở mọi ngã rẽ của thực tế. Cách tốt nhất để đảm bảo bạn đưa ra quyết định đúng đắn là tự hỏi bản thân xem đó có phải là một hành động tốt hay không. Hãy tự tin tiến hành nếu nó phù hợp với các giá trị của bạn và mang lại lợi ích lớn hơn. Nếu không, tốt nhất là tìm kiếm những cơ hội khác. Bạn không cần lãng phí thời gian vào những việc không cần thiết. Vũ trụ luôn có cách hỗ trợ những người chọn sự chính trực hơn sự tiện lợi.`,
    `Cuộc sống là việc đưa ra những lựa chọn có lợi cho cá nhân chúng ta và cộng hưởng với niềm tin đạo đức của chúng ta. Con đường liêm chính có thể không phải lúc nào cũng dễ dàng nhất, nhưng nó luôn mang lại nhiều thành quả nhất. Câu chuyện của tôi là minh chứng cho sức mạnh của hành động đúng đắn và việc tốt, là lời nhắc nhở rằng ngay cả khi đối mặt với nghịch cảnh, việc lựa chọn con đường chính trực có thể dẫn đến những kết quả viên mãn và có ý nghĩa.`,
  ],
  "title4": `Cân bằng hạnh phúc bản thân`,
  "paragraphs4": [
    `Trong hành trình tìm kiếm hạnh phúc, tôi từng tin rằng mang lại niềm vui cho người khác vốn dĩ sẽ khiến tôi hạnh phúc. Tôi đầu tư sức lực của mình vào việc xây dựng và nuôi dưỡng những cuộc tụ họp dựa trên sở thích, nghĩ rằng hạnh phúc của họ sẽ phản ánh trong cuộc sống của tôi. Lúc đầu, cách tiếp cận này có vẻ thành công - khi các nhóm phát triển mạnh mẽ, tâm trạng của tôi cũng vậy.`,
    `Tuy nhiên, theo thời gian, tôi nhận ra bản chất phù du của niềm hạnh phúc này. Lẽ ra nó có thể nhất quán hơn, đặc biệt khi thảo luận về những khó khăn trong nhóm hoặc đối mặt với sự không hài lòng của các thành viên trong nhóm. Nó gợi lên một sự tự suy xét nghiêm túc: Hạnh phúc này là của tôi hay là sự đáp lại cảm xúc của người khác? Tôi dần dần nhận ra rằng cảm giác vui vẻ của mình thường phụ thuộc quá nhiều vào các yếu tố bên ngoài, một nhận thức đáng báo động và sáng tỏ.`,
    `Cái nhìn sâu sắc này đã dẫn đến một sự thay đổi quan trọng trong nhận thức của tôi. Tôi hiểu rằng mặc dù việc đóng góp vào hạnh phúc của người khác là điều đáng làm nhưng điều đó không nên làm tổn hại đến hạnh phúc của tôi. Tôi học được rằng hạnh phúc đích thực bắt nguồn từ bên trong và cần được vun trồng một cách độc lập khỏi ảnh hưởng của người khác.`,
    `Áp dụng quan điểm mới này, tôi bắt đầu ưu tiên hạnh phúc của bản thân lên hàng đầu. Sự thay đổi này không hề ích kỷ mà là một bước quan trọng hướng tới việc đạt được hạnh phúc đích thực. Bằng cách tập trung vào sự hài lòng bên trong, tôi có thể mang đến cho người khác một hình thức hạnh phúc sâu sắc hơn, chân thực hơn. Điều đó nâng cao cuộc sống của tôi và cho phép sự hiện diện của tôi có tác động tích cực hơn đến cuộc sống của những người xung quanh.`,
    `Câu chuyện này là minh chứng cho ý tưởng rằng khi chúng ta chăm sóc sức khỏe tinh thần của mình, chúng ta được trang bị tốt hơn để tác động tích cực đến cuộc sống của người khác, tạo ra một chu kỳ niềm vui có lợi cho tất cả những người liên quan.`
  ],
  "title5": `Làm những gì bạn yêu thích có trách nhiệm`,
  "paragraphs5": [
    `Năm 2007, khi tôi vừa tốt nghiệp đại học xong. Lúc đó thực sự tôi rất muốn kiếm tiền vì đã phải chịu cảnh khổ thiếu thốn quá lâu. 
    Năm đó chứng khoán và nhà đất phát triển mạnh tại Vietnam, đặc biệt tại thành phố Hồ Chí Minh. Có nghĩa là làm gì liên quan tới cái đó rất nhanh kiếm được tiền. Tôi từng thấy có những người làm chứng khoán trẻ lắm mà họ đã sở hữu tài sản lớn.
    Bạn của tôi, người trong giới tài chính nói với tôi: Bạn nên vào công ty chứng khoán làm IT đi, vừa vẫn làm kĩ thuật mà có thể thỉnh thoảng kiếm chút chứng khoán. Bạn tôi biết xưa nay tôi khá cứng đầu và biết tôi thích IT nên khuyên vậy.`,
    `Tôi chỉ im lặng và lắc đầu. Tôi biết bạn tôi nghĩ tốt cho tôi và muốn tôi nhanh chóng phất lên vì tôi cũng có khả năng.
    Nhưng chỉ tôi biết, tôi yêu việc làm phần mềm, và tôi chỉ muốn tập chung cho việc đó. Mới lại nghề phát triển phần mềm cũng kiếm được khá, đủ để tôi sống tốt và giúp đỡ gia đình.
    Rồi tôi cứ lẳng lặng theo nghề, công việc khá dễ chịu và nhàn do khả năng tôi khá tốt. Tôi chợt nhận thấy tôi muốn ra nước ngoài học thêm về ngành máy tính.
    Tôi dồn tiền đi học tiếng anh, mặc dù học tiếng anh rất đắt đỏ, và nhiều người nói tiết kiệm tiền đầu tư thì hơn. Tôi vẫn kiên quyết thử vì tôi rất muốn học hỏi thêm về máy tính.`,
    `Rồi tôi cũng dành được học bổng đi học tại Hàn Quốc. Sau đó tôi làm phát triển phần mềm cho một công ty Hàn Quốc, và lần đầu nếm trải hương vị phát triển phần mềm thế giới (worldwide software).
    Quả thực rất thú vị, làm tôi càng yêu việc tôi làm hơn. Tôi quyết định sang Mỹ để học thêm phần mềm và làm phần mềm tiếp. 2015, tôi sang Mỹ học tiếp và sau đó đi làm cho 2 công ty ở Cali và New York. 
    Cảm giác rất thú vị khi làm trong môi trường mà nhiều kĩ sư ở nhiều nước khác nhau, nhưng có chung một niềm vui là phát triển phần mềm. 
    Nhưng lúc này tôi bắt đầu cảm giác thèm muốn phát triển công ty riêng của tôi.`,
    `Tôi quyết định rời công ty ở New York về VN mở công ty phần mềm. Quyết định này thực sự rất khó cho chính tôi vì tôi đang có một công việc rất tốt, có lẽ tốt nhất từ trước tới giờ. Nhưng quả thực khi bạn đạt tới mức nào đó, bạn sẽ muốn làm một sản phẩm của riêng bạn. Tôi đã trở về và khởi nghiệp để thoả mãn những đam mê khát khao của mình. Sau 2 năm mọi việc không thực sự như ý muốn. Tôi đã quay trở lại làm phần mềm. 
    Tình cờ trường đại học bên Mỹ gửi thông báo tuyển giảng viên. Giảng dạy cũng là niềm yêu thích của tôi nhưng chưa thực sự có cơ hội. Tôi đã nộp đơn xin việc đó.`,
    `Và rồi sau hơn 10 năm, tôi trở thành giảng viên ngành máy tính của đại học ở Mỹ. Tôi chủ yếu dạy học trò làm sao ứng dụng kiến thức vào thực tế mà mình đã được trải nghiệm.
    Quả thực con đường đi này là con đường đẹp nhất với tôi. Dù rằng nó không mang lại lợi ích lớn về vật chất, nhưng nó mang lại cảm xúc và hạnh phúc cho tôi.
    `,
    `Nhìn lại chặng đường này, tôi thấy mọi nơi tôi đi qua đều đầy lãng mạn, thăng có trầm có, nhưng sau tất cả là niềm vui và hạnh phúc. 
    Nếu ai đó hỏi tôi, được chọn lựa lại, tôi có thay đổi không. Không, chắc chắn là không, vì tôi đã làm những thứ mình yêu thích nhất, với tình yêu trong sáng nhất mà tôi tin rằng nó sẽ mang lại hạnh phúc dài lâu.Điều này đã được chứng tỏ hơn 20 năm qua.`,
    `Thực ra trong cuộc đời bạn khi bạn làm một điều gì bạn cũng tự phải đặt câu hỏi: Mục đích ý nghĩa lâu dài của mình trong việc này là gì? Mình có thực sự hạnh phúc trong công việc này không? Nếu câu trả lời là không cho một trong hai câu hỏi trên bạn nên xem xét lại chính mình. Bạn phải nhìn thấy mục đích ý nghĩa của công việc bạn mới có động lực đi lâu và đi xa. Bạn phải thực sự hạnh phúc trong công việc thì bạn mới đạt kết quả cao nhất trong công việc đó được.`,
    `Làm những gì bạn yêu thích với trách nhiệm cao nhất để rồi một ngày bạn sẽ có những gì bạn muốn.`
  ],
  "title6": `Hãy là chính mình`,
  "paragraphs6": [
    `Tôi lớn lên trong một ngôi làng cổ kính và thanh bình, nơi đã hình thành nên giá trị trung thực và siêng năng của tôi ngay từ khi còn nhỏ. Khi trưởng thành, tôi gặp nhiều ý kiến khuyên tôi nên từ bỏ những đặc điểm này để thành công trong thế giới hiện đại phát triển nhanh chóng. Họ lấy sự giàu có và cuộc sống dường như tốt hơn của người khác làm bằng chứng.`,
    `Bất chấp những lập luận của họ, tôi vẫn giữ vững niềm tin của mình. Trong suốt thời đại học, tôi đã xuất sắc nhờ luôn sống thật với chính mình, làm việc chăm chỉ và đạt được thành tích một cách trung thực. Sau khi tốt nghiệp, những lời xì xào vẫn dai dẳng, thúc giục tôi thích nghi với cuộc sống thành thị và theo đuổi sự giàu có.`,
    `Tuy nhiên, tiếng nói bên trong tôi vẫn kiên định, nhắc nhở tôi phải giữ gìn bản chất con người mình. Niềm tin này đã đưa tôi đi suốt hành trình sự nghiệp của mình ở Hoa Kỳ và Hàn Quốc, nơi tôi phát hiện ra rằng sự trung thực và làm việc chăm chỉ được đánh giá cao trên toàn cầu, đặc biệt là ở những người lao động nước ngoài. Đó là một nhận thức đầy an ủi rằng tất cả những gì tôi cần làm là là chính mình.`,
    `Trong bất kỳ hoàn cảnh nào, tôi đã học được tầm quan trọng của việc sống thật với chính mình, duy trì một tâm hồn không tì vết và chân thành với cả bản thân và người khác. Chấp nhận con người đích thực của bạn là chìa khóa cho một cuộc sống trọn vẹn.`
  ],
  "title7": `Thân thiện`,
  "paragraphs7": [
    `Tôi có xu hướng đổ lỗi cho bản thân và người khác về bất kỳ sai sót nào. Khi mọi thứ trở nên tồi tệ, theo bản năng tôi sẽ tìm ra lỗi lầm và đổ lỗi. Dù là lỗi của bạn bè hay bản thân tôi thì kết quả vẫn luôn như nhau: các mối quan hệ căng thẳng và trái tim nặng trĩu. Đổ lỗi cho bạn bè dẫn đến căng thẳng và oán giận, ngay cả khi họ thừa nhận lỗi lầm của mình. Cách tôi xử lý tình huống chỉ đẩy họ ra xa. Và khi mình là người có lỗi thì việc tự trách mình khiến tôi cảm thấy vô cùng tủi thân. Dù thế nào đi nữa, tôi cũng bị mắc kẹt trong vòng luẩn quẩn của sự tiêu cực và lãng phí thời gian.`,
    `Vài năm trước, tôi nhận ra sự vô ích của cách tiếp cận này và quyết định thay đổi. Trong mọi tình huống thử thách, tôi đều bắt đầu bằng việc xác định vấn đề. Nếu đó là lỗi của tôi, tôi sẽ tìm nguyên nhân sâu xa và chấp nhận sự không hoàn hảo của mình. Nếu một người bạn phải chịu trách nhiệm, tôi đã suy nghĩ kỹ về cách giải quyết vấn đề mà không phán xét, đưa ra sự hỗ trợ thay vì chỉ trích.`,
    `Sự thay đổi thái độ này đã thay đổi cuộc đời tôi. Các mối quan hệ của tôi trở nên suôn sẻ hơn, cả về mặt cá nhân lẫn công việc. Tôi tránh xa những drama không cần thiết và tìm thấy nhiều niềm vui hơn trong những tương tác hàng ngày của mình. Cuộc sống trở nên trọn vẹn hơn khi tôi chấp nhận những điểm không hoàn hảo của nó và tập trung vào sự phát triển tích cực.`,
    `Cuộc hành trình đã dạy cho tôi một bài học quý giá: phản ứng của chúng ta trước nghịch cảnh sẽ định hình cuộc sống của chúng ta. Thân thiện với bạn bè, kẻ thù và quan trọng nhất là chính chúng ta là chìa khóa để tồn tại hạnh phúc hơn.`
  ],
  "title8": `Biết yêu thương`,
  "paragraphs8": [
    `Khi còn trẻ, tôi đã trực tiếp trải qua cảnh nghèo khó. Khó khăn này đã dạy tôi giá trị của việc có đủ. Khi nỗ lực cải thiện cuộc sống của mình, tôi ưu tiên giúp đỡ những người vẫn đang phải vật lộn với nghèo đói.`,
    `Tôi luôn có mong muốn mạnh mẽ là giúp đỡ những học sinh nghèo nhưng tài năng. Ở vị trí của họ, tôi hiểu những thách thức họ gặp phải. Làm sao người trẻ có thể tập trung vào việc học khi thiếu những nhu yếu phẩm cơ bản như lương thực? Ngay cả đối với những người đủ ăn, việc xuất sắc trong học tập cũng không phải là điều dễ dàng. Tuy nhiên, tôi đã chứng kiến ​​nhiều sinh viên vượt lên trên hoàn cảnh và đạt được thành công lớn trong học tập. Với một bàn tay giúp đỡ, họ có thể trở thành những thành viên có giá trị của xã hội trong tương lai.`,
    `Cuộc sống đầy những thay đổi và thách thức nhanh chóng. Chúng ta cần những cá nhân có thể vượt qua những thách thức này để cải thiện thế giới của chúng ta. Những người đã phải đối mặt và vượt qua khó khăn trong cuộc sống thường được trang bị tốt nhất để làm điều đó.`,
    `Tôi đã nỗ lực hỗ trợ những học sinh như vậy và điều đó mang lại cho tôi niềm vui và mục đích lớn lao. Nhiều người tin rằng sự giàu có đáng kể là cần thiết để giúp đỡ người khác. Tuy nhiên, tôi không đồng ý. Tôi tin rằng lòng trắc ẩn thực sự buộc chúng ta phải giúp đỡ bất cứ khi nào có thể, bất kể tình trạng tài chính của chúng ta như thế nào. Nếu bạn có mong muốn giúp đỡ người khác, bạn sẽ tìm ra cách để làm điều đó. Hãy tiếp cận nó với lòng trắc ẩn và bạn sẽ tìm thấy hạnh phúc trong chính hành động đó.`
  ],
  "title9": `Thúc đẩy các mối quan hệ tích cực`,
  "paragraphs9": [
    `Khi chúng ta định hướng trong cuộc sống, các mối quan hệ đóng một vai trò quan trọng trong việc hình thành trải nghiệm và hạnh phúc của chúng ta. Suy ngẫm về hành trình của chính mình, tôi dần hiểu được giá trị đích thực của việc nuôi dưỡng các mối quan hệ tích cực.`,
    `Trong những năm còn trẻ, tôi ưu tiên số lượng hơn chất lượng, tin rằng có nhiều mối quan hệ sẽ đảm bảo được hỗ trợ trong mọi tình huống. Tuy nhiên, cách tiếp cận này khiến tôi nhận ra rằng không phải mối quan hệ nào cũng có lợi. Một số thì hời hợt, mang lại cảm giác an toàn giả tạo.`,
    `Theo thời gian, tôi học được rằng sức mạnh thực sự nằm ở việc nuôi dưỡng những mối quan hệ tích cực và có ý nghĩa. Đây là những kết nối nơi sự tôn trọng, hỗ trợ và phát triển lẫn nhau phát triển mạnh mẽ, tạo ra tình huống đôi bên cùng có lợi. Ngược lại, những mối quan hệ độc hại làm tiêu hao năng lượng và cản trở sự phát triển cá nhân.`,
    `Bây giờ, tôi tập trung vào việc nuôi dưỡng những mối quan hệ mang lại những điều tốt đẹp nhất cho tôi và những người khác. Tôi phát hiện ra rằng bằng cách đầu tư vào những kết nối tích cực, cuộc sống của tôi trở nên trọn vẹn và phong phú hơn.`,
    `Cuộc sống là một chuỗi những lựa chọn và việc lựa chọn những mối quan hệ phù hợp là điều tối quan trọng. Khi nuôi dưỡng những mối quan hệ tích cực, chúng ta không chỉ cải thiện cuộc sống của chính mình mà còn góp phần tạo nên một thế giới tốt đẹp hơn, kết nối hơn.`
  ],
  "title10": `Có lối sống lành mạnh`,
  "paragraphs10": [
    `Cách đây vài năm, tôi đã phải thức tỉnh nghiêm trọng khi được chẩn đoán có vấn đề về gan. Lối sống của tôi, đặc trưng bởi làm việc quá sức, chế độ ăn uống kém và uống quá nhiều rượu, đã gây tổn hại cho sức khỏe của tôi. Việc nhận ra rằng tôi phải thay đổi vừa đáng sợ vừa cần thiết.`,
    `Tôi bắt đầu hành trình thay đổi lối sống của mình. Đầu tiên, tôi giới hạn thời gian làm việc của mình ở mức có thể quản lý được 8 giờ mỗi ngày, hiểu rằng cuộc sống không chỉ có công việc. Tôi bắt đầu ưu tiên thời gian cho gia đình, bạn bè và sở thích.`,
    `Thứ hai, tôi đã điều chỉnh lại chế độ ăn uống của mình. Tôi kết hợp nhiều rau hơn và giảm lượng thịt cũng như thực phẩm giàu calo. Thức ăn nhanh trở nên hiếm và tôi cắt giảm rượu để bảo vệ gan.`,
    `Giấc ngủ trở thành ưu tiên hàng đầu. Thay vì ngủ 6 tiếng như trước đây, giờ tôi đảm bảo mình ngủ 7-9 tiếng mỗi đêm. Chỉ riêng sự thay đổi này đã cải thiện đáng kể mức năng lượng và năng suất của tôi.`,
    `Tập thể dục đã trở thành một phần thường xuyên trong thói quen của tôi. Cho dù đó là chạy bộ, đi bộ, bơi lội hay chạy bộ, tôi đảm bảo duy trì hoạt động ít nhất 30 phút mỗi ngày. Thói quen này rất quan trọng trong việc duy trì sức khỏe thể chất của tôi, đặc biệt vì công việc của tôi đòi hỏi phải làm việc nhiều giờ trước máy tính.`,
    `Cuối cùng, tôi tập trung vào sức khỏe tinh thần của mình. Trong ngành công nghệ có nhịp độ phát triển nhanh và thường xuyên căng thẳng, việc giữ vững lập trường là điều cần thiết. Thiền đã trở thành một thực hành hàng ngày đối với tôi, nâng cao nhận thức và hạnh phúc tổng thể của tôi.`,
    `Cuộc sống của tôi đã thay đổi đáng kể kể từ khi áp dụng những thói quen lành mạnh này. Tôi tin rằng bất kỳ ai cũng có thể đạt được sự chuyển đổi tương tự bằng cách cân bằng giữa công việc, giấc ngủ, dinh dưỡng, hoạt động thể chất và sức khỏe tinh thần. Một lối sống lành mạnh nằm trong tầm tay và đó là một hành trình đáng tham gia.`
  ]
}

export default Vietnamese;