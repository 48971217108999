
const English = {
  "norman-vincent-peale":"The way to happiness: Keep your heart free from hate, your mind free from worry. Live simply, expect little, give much. Scatter sunshine, forget self, think of others. Try this for a week and you will be surprised.",
  "mahatma-gandhi-quote1": "Happiness is when what you think, what you say, and what you do are in harmony.",
  "albert-schweitzer-quote": "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful.",
  "maharishi-yogi-quote": "Thoughts are the seeds of action. Action leads to fulfillment.",
  "dalai-lama-quote": "The purpose of our lives is to be happy.",
  "steve-jobs-quote":"Your time is limited, don't waste it living someone else's life.", 
  "martin-luther-king-quote": "The time is always right to do what is right.",
  "gro-harlem-brundtland-quote": "Sustainable development is development that meets the needs of the present without compromising the ability of future generations to meet their own needs.",
  "mahatma-gandhi-quote2": "Be the change that you wish to see in the world.",
  "aristotle-quote": "We are what we repeatedly do. Excellence, then, is not an act, but a habit.",
  "eleanor-roosevelt-quote": "The future belongs to those who believe in the beauty of their dreams.",
  "henry-david-thoreau-quote": "Go confidently in the direction of your dreams. Live the life you have imagined.",
  "nelson-mandela-quote": "Education is the most powerful weapon which you can use to change the world.",
  "thankyou": "Thank you so much.",
  "happinesspath": "Happiness Path",
  "lifeprinciples": "Life Principles",
  "author": "Author",
  "profile":[
    `Welcome to my website!`,
    `I am a computer professional with a rich, international background in Vietnam, South Korea, and the U.S., covering diverse roles from software engineer to educator. After earning degrees in information technology and Computer Networks from universities in Vietnam and South Korea, I completed another Master’s in Computer Science in the U.S. Currently, I teach Computer Science in the U.S., where I share my knowledge and experience with enthusiastic students.`,
    `My passion lies in software development and exploring cutting-edge technologies. I have also been a practitioner of Transcendental Meditation since 2015, which equips me to manage stress and embrace life's rapid changes effectively.`,
    `This website serves as a platform to share principles and experiences that have shaped my fulfilling and balanced life. Thank you for visiting, and I hope to inspire and add value to your journey.`
  ],
  "title1": `Journey to Genuine Happiness`,
  "paragraphs1":[
    `Welcome to my transformative journey, a tale of turning adversity into a happy life. Twenty years ago, I decided to leave my hometown to find a way to uplift my family from the depths of poverty and hunger. This desire took me across continents, from the bustling streets of Vietnam to the vibrant cities of South Korea and the United States. As I experienced the complexities of life in these diverse cultures, I wrestled with the age-old question: What is the true essence of happiness?`,
    `Initially, I studied with many people surrounding me and believed that if I could make more money, I would have a happy life. However, two decades of experience have depicted a different picture to me. I have dicovered that wealth is needed to fight against poverty and hunger, but it is not the only thing to create a contented life. I have witnessed that material life does not guarantee joy. `,
    `My realizations have been gradually deepening. I have discovered that happiness is more about mastering the art of overcoming life's inevitable challenges. It is more about finding peace and joy daily, regardless of anything. I have fought against the daunting obstacles in my life, like poverty, illness, and family separation. Through it all, I have learned that worldly possessions are just fleeting comforts. True happiness is from within, from unshakeable peace and joy daily in a simple life.`,
    `If you yearn for a life rich in genuine happiness, where peace and joy aren't just fleeting moments but a constant state of being, allow my journey to inspire you. It's not just a testament to the power of human spirit and resilience, but a reminder that the greatest treasures in life aren't those we can hold in our hands but those we carry in our hearts.`
  ],
  "title2": `Knowledge First`,
  "paragraphs2":[
    `I was 17 years old, standing at a pivotal crossroads in life. Most of my friends went to study at universities near my hometown. Still, I decided to shape my future: I left my hometown to pursue higher education in my country's largest city (Ho Chi Minh City, Vietnam). With a heart full of dreams and a mind eager to learn, I was fortunate to be accepted into one of the country's most prestigious universities. It was more than an academic opportunity and a beacon of hope for a better future.`,
    `However, I know that this opportunity came with its challenges. Financial support from my family was limited, and I found myself grappling with the reality of self-sustenance in the bustling city. Determined to forge my path, I worked part-time as a math tutor. This job was not just a means of survival but my commitment to education. `,
    `Earning money was exciting and attractive. The allure of making more by increasing my tutoring hours was tempting, but I understood deviating from my academic concentration would compromise my long-term goals. So, I made a choice that seemed counterintuitive to many: I borrowed money from friends and relatives and accepted minimal financial aid from my family. This decision was fueled by a firm belief that my education was an investment in my better future, a foundation upon which I could build a life of stability and success.`,
    `Five years later, I graduated with a good grade, a wealth of knowledge, and a newfound confidence. This education was not just an academic certification but also a key that unlocked doors to opportunities I had once only dreamed of. I quickly got a good job, an achievement that began a new chapter where financial constraints no longer shadowed my aspirations.`,
    `It was just starting my life. The knowledge and skills I acquired allowed me to study, work, and thrive in vibrant places in South Korea and the United States. It has been a remarkable journey from the classrooms of Vietnam to the global stage, providing that education is indeed the greatest equalizer.`,
    `Looking back, if I had prioritized immediate earnings over my education, my life might have been different. Every challenge I embraced, and each sacrifice I made served a singular goal: to evolve into an engineer with deep knowledge and refined skills. And in this pursuit, I succeeded, charting a course over a decade across multiple countries. `,
    `In conclusion, knowledge should always come first. Embrace what you love, dedicate yourself to learning, and excel in your chosen field. Knowledge is not just an academic journey; it is a journey that paves the way to a fulfilling and prosperous life. Keep learning, keep gaining new knowledge.`
  ],
  "title3": `Right Action`,
  "paragraphs3": [
    `A few years ago, I faced a pivotal moment in my life. My startup had failed, leaving me in a precarious financial position. To add to my troubles, I was diagnosed with a severe health condition, amplifying my need for a stable income. I began a job search during this challenging time and soon received several attractive offers. Among these was a significant project that had been underway for two years.`,
    `I have spent nearly a month examining this project while simultaneously undergoing medical treatment. However, the more I delved into it, the more I recognized the apparent misalignment of my values and the project's trajectory. It seemed more focused on exhausting investor funds than delivering genuine value to clients. A good project should inherently benefit its customers and society, not merely consume resources.`,
    `Faced with a moral dilemma, I decided to turn down the lucrative offer. Instead, I chose a smaller project with another company that aligned more closely with my principles. As it turned out, it was a great decision. It, though financially less rewarding, proved to be incredibly fulfilling. The work environment was enriching, and I had an incredible working team. This positive atmosphere played a crucial role in my financial and health recovery. My health improved considerably within six months, and my work was moving forward quickly.`,
    `I have learned an invaluable lesson through this experience: you will always face choices at every crossroads in reality. The best way to ensure you make the right decision is to ask yourself if it is a good deed. Confidently proceed if it aligns with your values and serves the greater good. If not, it is wise to seek other opportunities. You do not need to waste your time on unnecessary stuff. The universe always has a way of supporting those who choose integrity over convenience.`,
    `Life is about making choices that benefit us personally and resonate with our ethical beliefs. The path of integrity may not always be the easiest, but it is invariably the most rewarding. My story is a testament to the power of right action and good deeds, a reminder that even in the face of adversity, choosing the path of integrity can lead to fulfilling and meaningful outcomes.`
  ],
  "title4": `Balance Self-Happiness`,
  "paragraphs4": [
    `In my quest for happiness, I once believed that bringing joy to others would inherently make me happy. I invested my energy into building and nurturing interest-based gatherings, thinking their happiness would reflect in my life. At first, this approach seemed successful - as the groups flourished, so did my mood.`,
    `However, over time, I realized the fleeting nature of this happiness. It could have been more consistent, especially when discussing difficulties with the group or facing dissatisfaction from group members. It prompted a critical self-reflection: Was this happiness mine or a response to others’ emotions? I gradually recognized that my sense of joy was too often dependent on external factors, an alarming and illuminating realization.`,
    `This insight led to a pivotal change in my awareness. I understood that while it is rewarding to contribute to others’ happiness, it should not come at the expense of my well-being. I learned that genuine happiness originates from within and should be cultivated independently from others’ influences.`,
    `Adopting this new perspective, I started prioritizing my own happiness first. This shift was not selfish but a crucial step towards achieving authentic well-being. By focusing on my inner contentment, I could offer others a deeper, more genuine form of happiness. It enhanced my life and enabled me to be a more positively impactful presence in the lives of those around me. `,
    `This story is a testament to the idea that when we take care of our emotional well-being, we are better equipped to positively impact the lives of others, creating a cycle of joy that benefits everyone involved.`
  ],
  "title5": `Do What You Love with Your Responsibilities`,
  "paragraphs5": [
    `In 2007, when I had just graduated from college. At that time, I really wanted to make money because I had suffered from poverty for so long.
    That year, stocks and real estate developed strongly in Vietnam, especially in Ho Chi Minh City. That means doing anything related to that can make money very quickly. I have seen very young stockbrokers who already own large assets. My friends, who were in the financial world, told me: you should go to a securities company to work in IT, while still doing engineering and occasionally earning some stocks. My friends knew that I've always been quite stubborn and like IT, so he advised me.`,
    `I just kept silent and shook my head. I know my friend thinks well of me and wants me to prosper quickly because I also have the ability.
    But only I know, I love making software, and I just want to focus on that. That new job as a software engineer also earns me a good income, enough for me to live well and help my family.`,
    `Then I quietly followed my career, the job was quite comfortable and leisurely because my abilities were quite good. I suddenly realized that I wanted to go abroad to study more about the computer industry. I put money into learning English, even though learning English is very expensive, and many people say it's better to save money and invest. I was still determined to try because I really wanted to learn more about computers.`,
    `Then I also won a scholarship to study in Korea. Then I worked as a software developer for a Korean company, and got my first taste of worldwide software development.`,
    `It's really fun, makes me love what I do even more. I decided to go to America to learn more software and continue making software. In 2015, I went to the US to continue studying and then worked for two companies in Cali and New York. It feels very interesting to work in an environment where many engineers are in many different countries, but have the same joy of software development. But at this time I began to feel the desire to develop my own company.`,
    `I decided to leave the company in New York to return to Vietnam to open a software company. This decision was really difficult for me because I have a very good job, perhaps the best ever. But indeed when you reach a certain level, you will want to make your own product. I returned and started a business to satisfy my passion and desire. After 2 years things didn't really go as expected. I went back to doing software.
    By chance, a university in the US sent out a notice to recruit lecturers. Teaching is also my passion but I haven't really had the opportunity. I applied for that job.`,
    `And then after more than 10 years, I became a computer lecturer at a university in the US. I mainly teach students how to apply knowledge into practice that I have experienced before.
    Indeed, this path is the most beautiful path for me. Even though it doesn't bring great material benefits, it brings me emotions and happiness.`,
    `Looking back on this journey, I see that everywhere I went was full of romance, ups and downs, but in the end there was joy and happiness.
    If someone asked me, if I could choose again, would I change? No, definitely not, because I did the things I love the most, with the purest love that I believe will bring lasting happiness. This has been testified over the past 20 years.`,
    `In fact, in your life, when you do something, you should ask yourself: What is my long-term meaningful purpose in this thing? Am I really happy in this job? If the answer is no to either of the above questions, you should reconsider. You must see the meaningful purpose of your work to have the motivation to go long and far. You must be truly happy at work to achieve the best results in that job.`,
    `Do what you love with your responsibilities and one day you will have what you want.`
  ],
  "title6": `Be Yourself`,
  "paragraphs6": [
    `I grew up in a quaint and serene village that shaped my values of honesty and diligence from an early age. As I matured, I encountered many voices suggesting that I should abandon these traits to succeed in the fast-paced modern world. They pointed to the wealth and seemingly better lives of others as proof.`,
    `Despite their arguments, I held firm to my beliefs. Throughout college, I excelled by staying true to myself, working hard, and earning my achievements honestly. Post-graduation, the murmurs persisted, urging me to conform to city life and chase riches.`,
    `Yet, my inner voice remained steadfast, reminding me to preserve the essence of who I am. This conviction carried me through my professional journey in the USA and Korea, where I discovered that honesty and hard work are universally valued, especially in foreign workers. It was a comforting realization that all I needed to do was be myself.`,
    `In any circumstance, I've learned the importance of staying true to oneself, maintaining an unblemished soul, and being sincere with both oneself and others. Embracing your authentic self is the key to a fulfilling life.`
  ],
  "title7": `Be Friendly`,
  "paragraphs7": [
    `Once, I was prone to blaming myself and others for any mishap. When things went awry, I would instinctively seek out faults and assign blame. Whether it was a friend or myself at fault, the outcome was always the same: strained relationships and a heavy heart. Blaming my friends led to tension and resentment, even when they acknowledged their mistakes. The way I handled the situation only pushed them away. And when I was the one at fault, the self-blame left me feeling deeply unhappy. Either way, I was trapped in a cycle of negativity and wasted time.`,
    `A few years ago, I realized the futility of this approach and decided to make a change. In every challenging situation, I started by identifying the problem. If it was my fault, I sought the root cause and accepted my imperfections. If a friend was responsible, I thought carefully about how to address the issue without judgment, offering my support instead of criticism.`,
    `This shift in attitude transformed my life. My relationships became smoother, both personally and professionally. I stepped away from unnecessary drama and found more joy in my daily interactions. Life became more fulfilling when I embraced its imperfections and focused on positive growth.`,
    `The journey taught me a valuable lesson: our response to adversity shapes our lives. Being friendly towards friends, foes, and most importantly, ourselves, is the key to a happier existence.`
  ],
  "title8": `Be Compassionate`,
  "paragraphs8": [
    `When I was young, I experienced poverty firsthand. This hardship taught me the value of having 'enough.' As I worked to improve my life, I made it a priority to give back to those still struggling with poverty.`,
    `I have always had a strong desire to help impoverished and talented students. Having been in their shoes, I understand the challenges they face. How can young people focus on their studies when they lack basic necessities like food? Even for those with enough to eat, excelling academically is no easy feat. However, I have seen many students rise above their circumstances and achieve great success in their studies. With a helping hand, they can become valuable members of society in the future.`,
    `Life is full of rapid changes and challenges. We need individuals who can navigate these challenges to improve our world. Those who have faced and overcome difficulties in their lives are often best equipped to do so.`,
    `I have made efforts to assist such students, and it brings me great joy and purpose. Many believe that substantial wealth is necessary to help others. However, I disagree. I believe that true compassion compels us to help whenever we can, regardless of our financial status. If you have the desire to help others, you will find a way to do so. Approach it with compassion, and you will find happiness in the act itself.`
  ],
  "title9": `Foster Positive Relationships`,
  "paragraphs9": [
    `As we navigate through life, our relationships play a crucial role in shaping our experiences and well-being. Reflecting on my own journey, I've come to understand the true value of fostering positive relationships.`,
    `In my younger years, I prioritized quantity over quality, believing that having numerous connections would guarantee support in any situation. However, this approach led me to realize that not all relationships were beneficial. Some were superficial, offering little more than a false sense of security.`,
    `Over time, I learned that true strength lies in nurturing positive, meaningful relationships. These are the connections where mutual respect, support, and growth flourish, creating a win-win situation for all involved. In contrast, toxic relationships drain energy and hinder personal development.`,
    `Now, I focus on cultivating relationships that bring out the best in me and others. I've discovered that by investing in positive connections, my life has become more fulfilling and enriched.`,
    `Life is a series of choices, and choosing the right relationships is paramount. When we foster positive relationships, we not only enhance our own lives but also contribute to a better, more connected world.`
  ],
  "title10": `Have a Healthy Lifestyle`,
  "paragraphs10": [
    `A few years ago, I faced a serious wake-up call when I was diagnosed with liver problems. My lifestyle, characterized by excessive work, poor diet, and too much alcohol, was taking a toll on my health. The realization that I had to change was both frightening and necessary.`,
    `I embarked on a journey to transform my lifestyle. First, I limited my work hours to a manageable 8 hours a day, understanding that life is more than just work. I started to prioritize time with family, friends, and hobbies.`,
    `Secondly, I revamped my diet. I incorporated more vegetables and reduced my intake of meat and high-calorie foods. Fast food became a rarity, and I cut down on alcohol to protect my liver.`,
    `Sleep became a priority. Instead of the 6 hours I used to get, I now ensure I sleep for 7-9 hours every night. This change alone has significantly improved my energy levels and productivity.`,
    `Exercise became a regular part of my routine. Whether it's jogging, walking, swimming, or running, I make sure to stay active for at least 30 minutes daily. This habit has been crucial in maintaining my physical health, especially since my job requires long hours in front of a computer.`,
    `Lastly, I focused on my mental health. In the fast-paced and often stressful tech industry, staying grounded is essential. Meditation has become a daily practice for me, enhancing my awareness and overall happiness.`,
    `My life has transformed dramatically since adopting these healthy habits. I believe that anyone can achieve a similar transformation by balancing work, sleep, nutrition, physical activity, and mental well-being. A healthy lifestyle is within reach, and it's a journey worth taking.`
  ],
}



export default English;