import { createContext } from "react";
import { IGlobalContext } from "../interfaces";


const GlobalContext = createContext<IGlobalContext>({state: null, dispatch: null});
export default GlobalContext;
export const ActionType = {
  SET_STATE: "Set State",
  SET_LANGUAGE: "Set Language"
}

export const LS_TV_LANGUAGE = "@ls-tv-language";


export interface Action {
  type: string,
  payload?: Object
}
export function reducer(state: any, action: Action): any{
  switch(action.type){
    case ActionType.SET_STATE:
      return {...action.payload};
    case ActionType.SET_LANGUAGE:
      return {...state, language: action.payload};
    
    default:
      return state;
  }
}

