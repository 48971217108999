import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import English from "./locales/English";
import Vietnamese from "./locales/Vietnamese";
import { LS_TV_LANGUAGE } from "../helpers/context";
const resources = {
  en: {
    translation: English
  },
  vi: {
    translation: Vietnamese
  }
};
let language = "vi";
const res = localStorage.getItem(LS_TV_LANGUAGE);
if(res){
  language = res;
}
i18n.use(initReactI18next).init({
  resources,
  lng: language,
  keySeparator: false,
  fallbackLng: 'en', // Fallback language
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
