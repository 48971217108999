import { useContext, useEffect, useState } from "react";
import { Container, Text, H2, Button, Input, Image, Footer } from "typetailui";
import { useTranslation } from "react-i18next";
import GlobalContext, { ActionType, LS_TV_LANGUAGE } from "../helpers/context";
import { LANGUAGES, STORY_LENGTH } from "../helpers/constants";
import { registerEmail } from "../networking/subscribe";
import { useNavigate, useParams } from "react-router-dom";

export default function Story() {
  const params = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const id = parseInt(params.id!);
  const [title, setTitle] = useState("");
  const [paragraphs, setParagraphs] = useState<string[]>([]);
  const { state, dispatch } = useContext(GlobalContext);
  const [email, setEmail] = useState("");
  
  const onSubscribe = async () => {
    try {
      const text = email.trim();
      const regex = /^\S+@\S+\.\S+$/;
      if(!regex.test(text)){
        alert("Please enter the correct email.")
      }
      const ret = await registerEmail(text);
      if(ret.success){
        alert(t('thankyou'))
      }
    } catch (error) {
      
    }
  }

  const changeLanguage = (language: LANGUAGES) => {
    i18n.changeLanguage(language);
    dispatch({ type: ActionType.SET_LANGUAGE, payload: language });
    localStorage.setItem(LS_TV_LANGUAGE, language);
    setTitle(t(`title${id}`));
    setParagraphs(t(`paragraphs${id}`, { returnObjects: true }));
  };
  useEffect(() => {
    setTitle(t(`title${id}`));
    setParagraphs(t(`paragraphs${id}`, { returnObjects: true }));
  }, [id]);
  
  return (
    <Container className="flex flex-col justify-center items-center">
      <H2 className="text-xl font-semibold mb-4 text-center">
        {title}
      </H2>
      <Image source={`/stories/story${id}.png`} />
      {paragraphs.map((p: string, index: number) =>
        <Text key={index} className="mb-2">
          {p}
        </Text>
      )}
      {state.language === LANGUAGES.ENGLISH
        ? <Container className="flex flex-row justify-center items-center space-x-2">
            <Button title="Home" onClick={() => navigate("/")}  />
            {id < STORY_LENGTH &&
              <Button title="Next" onClick={() => navigate(`/story/${id+1}`)}  />}
            <Button
              title="Tiếng Việt"
              onClick={() => changeLanguage(LANGUAGES.VIETNAMESE)}
            />
          </Container>
        : <Container className="flex flex-row justify-center items-center space-x-2">
            <Button title="Trang đầu" onClick={() => navigate("/")} />
            {id < STORY_LENGTH &&
              <Button title="Kế tiếp" onClick={() => navigate(`/story/${id+1}`)} />}
            <Button
              title="English"
              onClick={() => changeLanguage(LANGUAGES.ENGLISH)}
            />
          </Container>}
      {/* <Container className="flex flex-row justify-center items-center space-x-2">
            <Input type="text" value={email} placeholder="Enter email" onChangeText={text => setEmail(text)}/>
            <Button
              title={state.language === LANGUAGES.ENGLISH ? "Subscribe" : "Đăng Kí"}
              onClick={onSubscribe}
            />
      </Container> */}
      <Text className="font-style: italic">
        Albert Schweitzer: <q>{t("welcome")}</q>
      </Text>
      <Footer company="ThaoVu.Org" facebookLink="https://www.facebook.com/groups/714840223938099" youtubeLink="https://www.youtube.com/channel/UCDDWktGMCaTS7IZVoY_77PA"/>
    </Container>
  );
}
