import { Link, useNavigate } from "react-router-dom";
import { Container, H1, Button, Text, Footer } from "typetailui";
import { LANGUAGES, STORY_LENGTH } from "../helpers/constants";
import { useTranslation } from "react-i18next";
import GlobalContext, { ActionType, LS_TV_LANGUAGE } from "../helpers/context";
import { useContext, useEffect, useState } from "react";

export default function LifePrinciples() {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const { t, i18n } = useTranslation();
  const [titles, setTitles] = useState<string []>([]);

  useEffect(() => {
    let stories = [];
    for(let i = 1; i <= STORY_LENGTH; i++){
      stories.push("title" + i);
    }
    setTitles(stories);
  }, [])
  const changeLanguage = (language: LANGUAGES) => {
    i18n.changeLanguage(language);
    dispatch({ type: ActionType.SET_LANGUAGE, payload: language });
    localStorage.setItem(LS_TV_LANGUAGE, language);
  };
  
  
  return (
    <Container className="flex flex-col items-center justify-center">
      <H1>
        {t("lifeprinciples")}
      </H1>
      <Container className="flex flex-col">
      {
        titles.map((title, index) => <Link to={`/story/${index + 1}`} className="text-blue-500 hover:text-blue-700 underline">{index+1}{". "}{t(title)}</Link>)
      }
      </Container>

      {state.language === LANGUAGES.ENGLISH
        ? <Container className="flex flex-row justify-center items-center space-x-2">
            <Button title="Home" onClick={() => navigate("/")}  />
            <Button
              title="Tiếng Việt"
              onClick={() => changeLanguage(LANGUAGES.VIETNAMESE)}
            />
          </Container>
        : <Container className="flex flex-row justify-center items-center space-x-2">
            <Button title="Trang đầu" onClick={() => navigate("/")} />
            <Button
              title="English"
              onClick={() => changeLanguage(LANGUAGES.ENGLISH)}
            />
          </Container>}
      <Text className="font-style: italic">
        Albert Schweitzer: <q>{t("welcome")}</q>
      </Text>
      <Footer
        company="ThaoVu.Org"
        facebookLink="https://www.facebook.com/groups/714840223938099"
        youtubeLink="https://www.youtube.com/channel/UCDDWktGMCaTS7IZVoY_77PA"
      />
    </Container>
  );
}
