import React, { useEffect, useReducer } from "react";
import "./App.css";
import Layout from "./components/Layout";
import GlobalContext, {
  ActionType,
  LS_TV_LANGUAGE,
  reducer
} from "./helpers/context";
import './i18n';
function App() {
  const [state, dispatch] = useReducer(reducer, { language: null });
  useEffect(() => {
    const res = localStorage.getItem(LS_TV_LANGUAGE);
    if(res){
      dispatch({type: ActionType.SET_LANGUAGE, payload: res});
    }else{
    }
  }, [])
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      <Layout />
    </GlobalContext.Provider>
  );
}

export default App;
