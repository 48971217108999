import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./Home";
import NotFoundError from "./NotFoundError";
import Story from "./Story";
import LifePrinciples from "./LifePrinciples";
import Author from "./Author";

const router = createBrowserRouter([
  {
    path: "",
    element: <Home/>
  },
  {
    path: "/life-principles",
    element: <LifePrinciples/>
  },
  {
    path: "/author",
    element: <Author/>
  },
  {
    path: "/story/:id",
    element: <Story/>
  },
  {
    path: "*",
    element: <NotFoundError />
  }
]);

export default function Layout() {
  return <RouterProvider router={router} />;
}