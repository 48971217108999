import { useContext } from "react";
import { BackgroundImage, Button, Container, Text, Footer } from "typetailui";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../helpers/constants";
import GlobalContext, { ActionType, LS_TV_LANGUAGE } from "../helpers/context";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const changeLanguage = (language: LANGUAGES) => {
    i18n.changeLanguage(language);
    dispatch({ type: ActionType.SET_LANGUAGE, payload: language });
    localStorage.setItem(LS_TV_LANGUAGE, language);
  };
  const { state, dispatch } = useContext(GlobalContext);
  const className = "italic";
  return (
    <Container className="flex flex-col justify-center items-center">
      <Container className="flex flex-col justify-center ">
      <Text className={className}>
          Dalai Lama: <q>{t("dalai-lama-quote")}</q>
        </Text>
        <Text className={className}>
          Mahatma Gandhi: <q>{t("mahatma-gandhi-quote1")}</q>
        </Text>
        <Text className={className}>
        Norman Vincent Peale: <q>{t("norman-vincent-peale")}</q>
        </Text>
        
        <Text className={className}>
          Albert Schweitzer: <q>{t("albert-schweitzer-quote")}</q>
        </Text>
        <Text className={className}>
          Steve Jobs: <q>{t("steve-jobs-quote")}</q>
        </Text>
        <Text className={className}>
          Maharishi Mahesh Yogi: <q>{t("maharishi-yogi-quote")}</q>
        </Text>
        <Text className={className}>
          Aristotle: <q>{t("aristotle-quote")}</q>
        </Text>
        <Text className={className}>
          Henry David Thoreau: <q>{t("henry-david-thoreau-quote")}</q>
        </Text>
        <Text className={className}>
          Eleanor Roosevelt: <q>{t("eleanor-roosevelt-quote")}</q>
        </Text>
        <Text className={className}>
          Mahatma Gandhi: <q>{t("mahatma-gandhi-quote2")}</q>
        </Text>
        <Text className={className}>
          Martin Luther King Jr: <q>{t("martin-luther-king-quote")}</q>
        </Text>
        <Text className={className}>
          Gro Harlem Brundtland: <q>{t("gro-harlem-brundtland-quote")}</q>
        </Text>
        <Text className={className}>
          Nelson Mandela: <q>{t("nelson-mandela-quote")}</q>
        </Text>
        <Container className="flex flex-row justify-center items-center space-x-2 bg-transparent">
          {/* <Button
              title={t("happinesspath")}
              onClick={() => navigate("/life-principles")}
            />
            <Button
              title={t("author")}
              onClick={() => navigate("/author")}
            /> */}
          {state.language === LANGUAGES.ENGLISH
            ? <Button
                title="Tiếng Việt"
                onClick={() => changeLanguage(LANGUAGES.VIETNAMESE)}
              />
            : <Button
                title="English"
                onClick={() => changeLanguage(LANGUAGES.ENGLISH)}
              />}
        </Container>
      </Container>
      <Footer
        company="ThaoVu.Org"
        facebookLink="https://www.facebook.com/groups/714840223938099"
        youtubeLink="https://www.youtube.com/channel/UCDDWktGMCaTS7IZVoY_77PA"
      />
    </Container>
  );
}
