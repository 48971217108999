import { Button, Container, H1 } from "typetailui";
import {useNavigate} from 'react-router-dom';
export default function NotFoundError(){
  const navigate = useNavigate()
  const reload = () => {
    navigate('/');
  }
  return (
    <Container className="flex flex-col items-center justify-center">
      <H1>Cannot find the path. Please check again your URL.</H1>
      <Button title="Reload" onClick={reload}/>
    </Container>
  )
}