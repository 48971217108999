import { useContext, useEffect, useState } from "react";
import { Container, Text, H2, Button, Image, Footer } from "typetailui";
import { useTranslation } from "react-i18next";
import GlobalContext, { ActionType, LS_TV_LANGUAGE } from "../helpers/context";
import { LANGUAGES, STORY_LENGTH } from "../helpers/constants";
import { useNavigate } from "react-router-dom";

export default function Author() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [title, setTitle] = useState("");
  const [paragraphs, setParagraphs] = useState<string[]>([]);
  const { state, dispatch } = useContext(GlobalContext);
  
  
  const changeLanguage = (language: LANGUAGES) => {
    i18n.changeLanguage(language);
    dispatch({ type: ActionType.SET_LANGUAGE, payload: language });
    localStorage.setItem(LS_TV_LANGUAGE, language);
    setTitle(t(`author`));
    setParagraphs(t(`profile`, { returnObjects: true }));
  };
  useEffect(() => {
    setTitle(t(`author`));
    setParagraphs(t(`profile`, { returnObjects: true }));
  }, []);
  
  return (
    <Container className="flex flex-col justify-center items-center">
      <H2 className="text-xl font-semibold mb-4 text-center">
        {title}
      </H2>
      <Image source={`/stories/profile.png`} />
      {paragraphs.map((p: string, index: number) =>
        <Text key={index} className="mb-2">
          {p}
        </Text>
      )}
      {state.language === LANGUAGES.ENGLISH
        ? <Container className="flex flex-row justify-center items-center space-x-2">
            <Button title="Home" onClick={() => navigate("/")}  />
            <Button
              title="Tiếng Việt"
              onClick={() => changeLanguage(LANGUAGES.VIETNAMESE)}
            />
          </Container>
        : <Container className="flex flex-row justify-center items-center space-x-2">
            <Button title="Trang đầu" onClick={() => navigate("/")} />
            <Button
              title="English"
              onClick={() => changeLanguage(LANGUAGES.ENGLISH)}
            />
          </Container>}
      <Footer company="ThaoVu.Org" facebookLink="https://www.facebook.com/groups/714840223938099" youtubeLink="https://www.youtube.com/channel/UCDDWktGMCaTS7IZVoY_77PA"/>
    </Container>
  );
}
